<template>
  <div>
    <b-row>
      <b-col>
        <b-card v-if="credores.length > 1">
          <b-row>
            <b-button
              variant="outline-warning"
              @click="resetGraficos()"
            >
              Filtro
            </b-button>
          </b-row>
        </b-card>
        <b-card>
          <b-card-body class="statistics-body">
            <b-row>
              <b-col
                v-for="item in cards"
                :key="item.icon"
                :class="item.customClass"
                @click="itemClick(item.url)"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      size="48"
                      :variant="item.color"
                    >
                      <feather-icon
                        size="24"
                        :icon="item.icon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ item.title }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ item.subtitle }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
        <section
          v-if="dadosGraficos"
          id="dashboard-ecommerce"
        >
          <b-row
            align-v="stretch"
          >
            <b-col
              cols="6"
            >
              <b-card
                :title="`Cobranças - R$ ${valorBr(cobrancaSoma)} `"
              >
                <chartjs-bar-chart
                  :credores="credores"
                  :credoresfiltro="credoresFiltro"
                  @somaCobranca="somaCobranca"
                />
              </b-card>
            </b-col>
            <b-col
              cols="6"
            >
              <b-card
                :title="`Acordos - ${totalAcordo}` "
              >
                <vue-apex-charts
                  ref="myChart"
                  type="bar"
                  height="325"
                  :options="statusAcordos.chartOptions"
                  :series="statusAcordos.series"
                />
              </b-card>
            </b-col>
            <b-col
              cols="6"
            >
              <b-card
                :title="`Operações - ${totalOperacao}`"
              >
                <vue-apex-charts
                  ref="myChart"
                  type="bar"
                  height="325"
                  :options="statusOperacoes.chartOptions"
                  :series="statusOperacoes.series"
                />
              </b-card>
            </b-col>
            <b-col
              cols="6"
            >
              <b-card
                :title="`Pendências - ${totalPendencia}`"
              >
                <vue-apex-charts
                  ref="myChart"
                  type="bar"
                  height="325"
                  :options="statusPendencias.chartOptions"
                  :series="statusPendencias.series"
                />
              </b-card>
            </b-col>
            <b-col
              cols="6"
            >
              <b-card
                :title="`Clientes - ${totalCliente}`"
              >
                <echart-doughnut
                  :data="localizacao"
                  @chartClick="openModal"
                />
              </b-card>
            </b-col>
            <b-col
              v-if="graficoPerfil"
              cols="6"
            >
              <b-card
                :title="`Perfil Cliente - ${totalClientes}`"
              >
                <echart-doughnut
                  :data="clientes"
                  @chartClick="openModalPerfil"
                />
              </b-card>
            </b-col>
            <b-modal
              ref="statusModal"
              :title="`${nomeStatus}`"
              size="lg"
            >
              <b-button
                variant="outline-warning"
                style="margin-bottom: 10px; float: right;"
                @click="exportFile"
              >
                Exportar excel
              </b-button>
              <b-table
                ref="selectableTable"
                striped
                bordered
                responsive
                :current-page="currentPage"
                :per-page="perPage"
                :fields="fields"
                :items="status[0]"
                class="text-center"
              >
                <template #cell(cpf_cnpj)="data">
                  {{ data.item.cpf_cnpj ? cpfCNPJ(data.item.cpf_cnpj, true) : '' }}
                </template>
                <template #cell(nomeStatus)>
                  {{ nomeStatus }}
                </template>
              </b-table>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  align="center"
                />
              </b-col>
            </b-modal>
            <b-modal
              ref="perfilModal"
              :title="`${nomePerfil}`"
              size="lg"
            >
              <b-button
                variant="outline-warning"
                style="margin-bottom: 10px; float: right;"
                @click="exportFilePerfil"
              >
                Exportar excel
              </b-button>
              <b-table
                ref="selectableTable"
                striped
                bordered
                responsive
                :current-page="currentPage"
                :per-page="perPage"
                :fields="fieldsPerfil"
                :items="perfil[0]"
                class="text-center"
              >
                <template #cell(cpf_cnpj)="data">
                  {{ data.item.cpf_cnpj ? cpfCNPJ(data.item.cpf_cnpj, true) : '' }}
                </template>
                <template #cell(nomePerfil)>
                  {{ nomePerfil }}
                </template>
              </b-table>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  size="sm"
                  align="center"
                />
              </b-col>
            </b-modal>
          </b-row>
        </section>
      </b-col>
    </b-row>
    <b-modal
      id="filtro"
      ref="filtro"
      @ok="getGraficos"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-1"
        >
          <v-select
            id="CREDOR"
            v-model="credoresFiltro"
            multiple
            :options="credores"
          />
        </b-col>
      </b-row>
    </b-modal>
    <b-overlay
      :show="load"
      variant="transparent"
      rounded="sm"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BMediaBody,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
  BModal,
  BOverlay,
  BTable,
  BPagination,
} from 'bootstrap-vue'

import { $themeColors } from '@themeConfig'
import vSelect from 'vue-select'
import VueApexCharts from 'vue-apexcharts'
import { saveAs } from 'file-saver'
import tree from '@/navigation/vertical'
import EchartDoughnut from './charts/EchartDoughnut.vue'
import ChartjsBarChart from './charts/ChartjsBarChart.vue'

import api from '../../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BMediaBody,
    BCardText,
    BMediaAside,
    BAvatar,
    BCardBody,
    BMedia,
    BButton,
    BModal,
    BOverlay,
    vSelect,
    VueApexCharts,
    ChartjsBarChart,
    EchartDoughnut,
    BTable,
    BPagination,
  },
  data() {
    return {
      graficoPerfil: false,
      totalClientes: 0,
      clientes: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      nomeStatus: '',
      status: [],
      selectedPerfil: '',
      nomePerfil: '',
      perfil: [],
      dadosGraficos: false,
      load: true,
      dados: null,
      credores: [],
      credoresFiltro: [],
      totalAcordo: 0,
      totalOperacao: 0,
      totalPendencia: 0,
      totalCliente: 0,
      cobrancaSoma: 0,
      fields: [
        {
          key: 'credor_id', label: 'Cliente',
        },
        {
          key: 'cpf_cnpj', label: 'CPF/CNPJ',
        },
        {
          key: 'nomeStatus', label: 'Status',
        },
      ],
      fieldsPerfil: [
        {
          key: 'nome_cliente', label: 'Cliente',
        },
        {
          key: 'cpf_cnpj', label: 'CPF/CNPJ',
        },
        {
          key: 'nomePerfil', label: 'Status',
        },
      ],
      cards: [
        {
          color: 'light-info',
          key: 'quantidade_acordos',
          customClass: 'mb-2 mb-sm-0',
          icon: 'ThumbsUpIcon',
          title: '',
          subtitle: 'Acordos',
          url: 'credor.acordos',
        },
        {
          color: 'light-primary',
          key: 'quantidade_campanhas',
          customClass: 'mb-2 mb-xl-0',
          icon: 'ClockIcon',
          title: '',
          subtitle: 'Campanhas',
          url: 'credor.campanha',
        },
        {
          color: 'light-success',
          key: 'quantidade_remessas',
          customClass: 'mb-2 mb-sm-0',
          icon: 'FolderIcon',
          title: '',
          subtitle: 'Remessas',
          url: 'credor.remessa',
        },
        {
          color: 'light-danger',
          key: 'quantidade_operacoes',
          customClass: '',
          icon: 'ActivityIcon',
          title: '',
          subtitle: 'Operações',
          url: 'credor.operacoes',
        },
        {
          color: 'light-warning',
          key: 'quantidade_clientes',
          customClass: '',
          icon: 'UsersIcon',
          title: '',
          subtitle: 'Clientes',
          url: 'credor.cliente',
        },
      ],
      localizacao: [],
      statusAcordos: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ['#662E9B'],
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: false,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          // yaxis: {
          //   // opposite: isRtl,
          // },
        },
      },
      statusOperacoes: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: ['#008000'],
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          // grid: {
          //   xaxis: {
          //     lines: {
          //       show: false,
          //     },
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
            categories: [],
          },
        },
      },

      statusPendencias: {
        series: [
          {
            data: [],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          colors: $themeColors.info,
          plotOptions: {
            bar: {
              horizontal: tree,
              barHeight: '30%',
              endingShape: 'rounded',
            },
          },
          // grid: {
          //   xaxis: {
          //     lines: {
          //       show: false,
          //     },
          //   },
          // },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [],
          },
          yaxis: {
            // opposite: isRtl,
            categories: [],
          },
        },
      },
    }
  },
  created() {
    this.getTotalizadores()
    this.getCredores()
    this.getGraficos()
    this.getPerfil()
  },
  methods: {
    async openModal(data) {
      this.status = []
      let selectedStatusLocalizacao = ''
      switch (data.name) {
        case 'Localizado':
          selectedStatusLocalizacao = 'localizado'
          break
        case 'Nao Localizado':
          selectedStatusLocalizacao = 'nao_localizado'
          break
        case 'Em Processo de Localizacao':
          selectedStatusLocalizacao = 'em_processo_localizacao'
          break
        default:
          break
      }
      await api.get('api/v1/credores/clientes_localizacao')
        .then(res => {
          Object.keys(res.data.dados).forEach(key => {
            const item = res.data.dados[key]
            if (key === selectedStatusLocalizacao) {
              this.status.push(item)
            }
          })
        })
      this.totalRows = this.status[0].length
      this.nomeStatus = data.name
      this.$refs.statusModal.show()
    },
    async openModalPerfil(data) {
      this.perfil = []
      let selectedPerfil = ''
      switch (data.name) {
        case 'Crônico':
          selectedPerfil = 'Crônico'
          break
        case 'Ocasional':
          selectedPerfil = 'Ocasional'
          break
        case 'Negligente':
          selectedPerfil = 'Negligente'
          break
        case 'Mau Pagador':
          selectedPerfil = 'Mau Pagador'
          break
        case 'Devolvido':
          selectedPerfil = 'Devolvido'
          break
        default:
          break
      }

      await api.post('api/v1/credores/clientes_perfis')
        .then(res => {
          Object.keys(res.data.dados).forEach(key => {
            const item = res.data.dados[key]
            if (key === selectedPerfil) {
              this.perfil.push(item)
            }
          })
        })
      this.totalRows = this.perfil[0].length
      this.nomePerfil = data.name
      this.$refs.perfilModal.show()
    },
    async exportFile() {
      const file = await this.getDataToExported()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExported() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório localização')
      const itemsPlan = this.status[0]

      worksheet.columns = [
        { header: 'Nome Cliente', key: 'nome_cliente' },
        { header: 'Cpf/Cnpj', key: 'cpf_cnpj' },
        { header: 'Status', key: this.nomeStatus },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.nome_cliente,
          item.cpf_cnpj,
          this.nomeStatus,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório localização', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    async exportFilePerfil() {
      const file = await this.getDataToExportedPerfil()
      if (file) {
        saveAs(file)
      }
    },
    async getDataToExportedPerfil() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Relatório Perfil de Cliente')
      const itemsPlan = this.perfil[0]

      worksheet.columns = [
        { header: 'Nome Cliente', key: 'nome_cliente' },
        { header: 'Cpf/Cnpj', key: 'cpf_cnpj' },
        { header: 'Perfil', key: this.nomePerfil },
      ]

      itemsPlan.map(item => {
        worksheet.addRow([
          item.nome_cliente,
          item.cpf_cnpj,
          this.nomePerfil,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })

      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Perfil de Cliente', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
    itemClick(name) {
      this.$router.push({ name })
    },
    resetGraficos() {
      this.dadosGraficos = false
      this.$refs.filtro.show()
    },
    getTotalizadores() {
      api.get('api/v1/dashboard/credor/totalizadores', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const { dados } = res.data
        const keys = Object.keys(dados)
        keys.map(key => {
          this.cards.map(item => {
            if (item.key === key) {
              item.title = dados[key]
            }
          })
        })
      })
    },
    getCredores() {
      const credores = JSON.parse(localStorage.getItem('credores'))
      credores.map(item => {
        this.credores.push({
          id: item.id,
          label: item.nome,
        })
      })
    },
    somaCobranca(soma) {
      this.cobrancaSoma = soma
    },
    getGraficos() {
      let body

      const idDefault = []
      this.credores.map(id => {
        idDefault.push(id.id)
      })

      const ids = []
      this.credoresFiltro.map(item => {
        ids.push(item.id)
      })

      if (this.credoresFiltro.length) {
        body = {
          credores: ids,
        }
      } else {
        body = {
          credores: idDefault,
        }
      }
      api.post('api/v1/credores/totalizadores/status', body, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        if (res.data.dados.localizacao) {
          res.data.dados.localizacao.map(loc => {
            if (loc.status === '') {
              loc.status = 'Em Processamento'
            }
          })
        } else {
          res.data.dados.localização.map(loc => {
            if (loc.localizacao === null) {
              loc.localizacao = 'Em Processamento'
            }
          })
        }

        const dt = res.data.dados
        this.load = false
        this.dadosGraficos = true

        // GRAFICO ACORDOS
        this.totalAcordo = 0
        const totalCredores = []
        if (dt.Acordos) {
          dt.Acordos.map(total => {
            totalCredores.push(total.total)
          })
          this.statusAcordos.series[0].data = totalCredores

          const uniqueStatus = [...new Set(dt.Acordos.map(status => status.status))]
          this.statusAcordos.chartOptions.xaxis.categories = uniqueStatus

          dt.Acordos.map(soma => {
            this.totalAcordo += parseFloat(soma.total)
          })
        } else {
          this.totalAcordo = 0
          const totalCredor = []
          const statusAcordo = []

          dt.status_acordo.map(status => {
            totalCredor.push(status.total)
          })
          this.statusAcordos.series[0].data = totalCredor

          dt.status_acordo.map(statusA => {
            statusAcordo.push(statusA.status)
          })
          this.statusAcordos.chartOptions.xaxis.categories = statusAcordo
          dt.status_acordo.map(soma => {
            this.totalAcordo += parseFloat(soma.total)
          })
        }

        // GRAFICO LOCALIZAÇÃO
        this.totalCliente = 0
        if (dt.localizacao) {
          this.localizacao = []
          dt.localizacao.map(total => {
            this.localizacao.push({
              name: total.status,
              value: total.total,
            })
          })
          dt.localizacao.map(soma => {
            this.totalCliente += parseFloat(soma.total)
          })
        } else {
          this.totalCliente = 0
          this.localizacao = []
          dt.localização.map(total => {
            this.localizacao.push({
              name: total.localizacao,
              value: total.total,
            })
          })
          dt.localização.map(soma => {
            this.totalCliente += parseFloat(soma.total)
          })
        }
        // GRAFICO OPERACOES
        this.totalOperacao = 0
        const operacoesCredores = []
        if (dt.operacao[0].status) {
          dt.operacao.map(ot => {
            operacoesCredores.push(ot.total)
          })
          this.statusOperacoes.series[0].data = operacoesCredores

          const uniqueNomeOperacoes = [...new Set(dt.operacao.map(ot => ot.status))]
          this.statusOperacoes.chartOptions.xaxis.categories = uniqueNomeOperacoes
          dt.operacao.map(soma => {
            this.totalOperacao += parseFloat(soma.total)
          })
        } else {
          this.totalOperacao = 0
          const operacaoCredorTotal = []
          const operacaoCredor = []
          dt.operacao.map(ot => {
            operacaoCredorTotal.push(ot.total)
          })
          this.statusOperacoes.series[0].data = operacaoCredorTotal

          dt.operacao.map(on => {
            operacaoCredor.push(on.nome)
          })
          this.statusOperacoes.chartOptions.xaxis.categories = operacaoCredor
          dt.operacao.map(soma => {
            this.totalOperacao += parseFloat(soma.total)
          })
        }

        // GRAFICO PENDENCIA
        this.totalPendencia = 0
        const pendenciasCredores = []
        if (dt.pendencia) {
          dt.pendencia.map(pt => {
            pendenciasCredores.push(pt.total)
          })
          this.statusPendencias.series[0].data = pendenciasCredores

          const uniqueNomePendencias = [...new Set(dt.pendencia.map(pt => pt.status))]
          this.statusPendencias.chartOptions.xaxis.categories = uniqueNomePendencias
          dt.pendencia.map(soma => {
            this.totalPendencia += parseFloat(soma.total)
          })
        } else {
          this.totalPendencia = 0
          const pendenciaCredorTotal = []
          const pendenciaCredor = []
          dt.motivo_pendencia.map(mt => {
            pendenciaCredorTotal.push(mt.total)
          })
          this.statusPendencias.series[0].data = pendenciaCredorTotal
          dt.motivo_pendencia.map(mn => {
            pendenciaCredor.push(mn.nome)
          })
          this.statusPendencias.chartOptions.xaxis.categories = pendenciaCredor
          dt.motivo_pendencia.map(soma => {
            this.totalPendencia += parseFloat(soma.total)
          })
        }
      })
    },
    getPerfil() {
      api.get('api/v1/perfil_cliente/listar/credor', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(res => {
        const dt = res.data.dados
        this.graficoPerfil = true
        // GRAFICO PERFIL
        const clientesPorPerfil = dt.reduce((acc, curr) => {
          if (curr.credor_id === this.credores[0].id) {
            curr.clientes.forEach(total => {
              const { perfil } = total
              if (acc[perfil]) {
                acc[perfil]++
              } else {
                acc[perfil] = 1
              }
            })
          }
          return acc
        }, {})
        this.clientes = Object.keys(clientesPorPerfil).map(perfil => ({
          name: perfil,
          value: clientesPorPerfil[perfil],
        }))
        this.totalClientes = this.clientes.reduce((acc, curr) => acc + curr.value, 0)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
