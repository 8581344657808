<template>
  <div>
    <b-row align-h="between">
      <b-col md="4">
        <b-row>
          <b-col>
            <label>Filtro por Credor</label>
            <v-select
              v-model="credorFilter"
              class="mb-1"
              :options="optionsCredor"
              multiple
            />
          </b-col>
          <b-col>
            <label>Filtro por Status</label>
            <v-select
              v-model="statusFilter"
              class="mb-1"
              :options="optionsStatus"
              multiple
            />
          </b-col>
        </b-row>
      </b-col>
      <b-row>
        <b-col
          md="4"
          class="mb-1"
        >
          <b-button
            variant="warning"
            class="mb-2 ml-1 mt-1"
            :disabled="op.length < 1"
            @click="openModal()"
          >
            Retirar Negativação
          </b-button>
        </b-col>
        <b-col
          md="6"
          class="mb-1"
        >
          <b-button
            variant="outline-primary"
            class="mb-2 ml-1 mt-2"
            @click="exportXlsx"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            Exportar excel
          </b-button>
        </b-col>
      </b-row>
    </b-row>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="listFiltered"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <template #head(check)>
        <b-form-checkbox
          v-model="selectAll"
        />
      </template>
      <template #cell(check)="data">
        <b-form-checkbox
          v-model="op"
          :value="data.item"
        />
      </template>
      <template #cell(acoes)="data">
        <b-row align-h="center">
          <b-col cols="auto">
            <feather-icon
              icon="NavigationIcon"
              class="color-icon mr-1 cursor-pointer"
              @click="setCliente(data.item)"
            />
          </b-col>
        </b-row>
      </template>
    </b-table>
    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
    <div>
      <b-modal
        ref="show-modal"
        title="Retirar Negativação"
        no-close-on-backdrop
        ok-title="Alterar"
        cancel-title="Cancelar"
        cancel-variant="danger"
        @ok="updateNegativacao"
      >
        <modal-retirada-negativacao
          ref="modal"
          :operacoes="op"
          @refreshGrid="refresh"
        />
      </b-modal>
    </div>
  </div>
</template>
<script>
import { BTable, BFormCheckbox, BButton, BRow, BCol, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import { saveAs } from 'file-saver'
import ModalRetiradaNegativacao from './tabs/ModalRetiradaNegativacao.vue'
import axios from '../../../../../axios-auth'

const ExcelJS = require('exceljs')

export default {
  components: {
    BTable,
    BFormCheckbox,
    BButton,
    BRow,
    BCol,
    vSelect,
    BPagination,
    ModalRetiradaNegativacao,
  },
  props: {
    itemsretirar: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      mtNegat: {
        options: [],
        id: [],
        selected: '',
      },
      op: [],
      fields: [
        'check',
        { key: 'id', label: 'ID OPERAÇÃO', class: 'text-center' },
        { key: 'numero_operacao', label: 'NÚMERO OPERAÇÃO', class: 'text-center' },
        { key: 'cliente_id', label: 'CLIENTE ID', class: 'text-center' },
        { key: 'credor', label: 'CREDOR', class: 'text-center' },
        { key: 'nome', label: 'NOME', class: 'text-center' },
        { key: 'cpf_cnpj', label: 'CPF', class: 'text-center' },
        { key: 'status_operacao', label: 'STATUS OPERAÇÃO', class: 'text-center' },
        { key: 'acoes', label: 'AÇÕES', class: 'text-center' },
      ],
      selectAll: false,
      credorFilter: [],
      atualizar: false,
      statusFilter: [],
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
    }
  },
  computed: {
    optionsCredor() {
      const { items } = this
      const credor = []

      items.map(item => {
        credor.push(item.credor)
      })
      const uniqueCredor = [...new Set(credor)]
      return uniqueCredor
    },
    optionsStatus() {
      const { items } = this
      const status = []

      items.map(item => {
        status.push(item.status_operacao)
      })
      const uniqueStatus = [...new Set(status)]
      return uniqueStatus
    },
    listFiltered() {
      const {
        items, credorFilter: cf, statusFilter: sf,
      } = this
      let list = items

      if (cf.length > 0 || sf.length > 0) {
        list = []

        // Filtro por credor
        let newFilterCred = []
        const filterListCred = []
        list.length > 0 ? (newFilterCred = list) : (newFilterCred = items)
        for (let i = 0; i <= cf.length - 1; i++) {
          // eslint-disable-next-line no-loop-func
          newFilterCred.map(item => {
            if (item.credor === cf[i]) {
              filterListCred.push(item)
            }
          })
          list = filterListCred
        }
        // Filtro por status
        let newFilterStatus = []
        const filterListStatus = []
        list.length > 0 ? (newFilterStatus = list) : (newFilterStatus = items)
        for (let i = 0; i <= sf.length - 1; i++) {
          newFilterStatus.map(item => {
            if (item.status_operacao === sf[i]) {
              filterListStatus.push(item)
            }
          })
          list = filterListStatus
        }
      }
      this.totalRows = list.length
      return list
    },
  },
  watch: {
    selectAll(n) {
      n ? this.selectedAll() : this.clearSelect()
    },
  },
  async mounted() {
    this.items = this.itemsretirar
  },
  methods: {
    clearSelect() {
      this.op = []
    },
    selectedAll() {
      this.op = []
      this.itemsretirar.map(dt => {
        this.op.push(dt)
      })
    },
    async setCliente(item) {
      this.$router.push({ name: 'negociacao-cliente', params: { id: item.cliente_id } })
    },
    // função pra chamar o modal do componente
    openModal() {
      this.$refs['show-modal'].show()
    },
    // função pra chamar a função dentro do componente
    updateNegativacao() {
      this.$refs.modal.updateNegativacao()
    },
    async refresh(status) {
      this.atualizar = status
      await this.updateGrid()
    },
    updateGrid() {
      this.items = []
      this.clearSelect()
      axios.get('api/v1/operacoes/listar/retirar/negativacao', {
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
        },
      })
        .then(res => {
          res.data.dados.map(item => {
            this.items.push(item)
          })
        })
    },
    async exportXlsx() {
      const file = await this.getXlsx()
      if (file) {
        saveAs(file)
      }
    },
    async getXlsx() {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Retirar Negativação')
      const itemsPlan = this.listFiltered

      worksheet.columns = [
        { key: 'id', header: 'ID OPERAÇÃO' },
        { key: 'numero_operacao', header: 'NÚMERO OPERAÇÃO' },
        { key: 'cliente_id', header: 'CLIENTE ID' },
        { key: 'credor', header: 'CREDOR' },
        { key: 'nome', header: 'NOME' },
        { key: 'cpf_cnpj', header: 'CPF' },
        { key: 'status_operacao', header: 'STATUS OPERAÇÃO' },
        { key: 'forma_pagamento', header: 'FORMA PAGAMENTO' },
      ]
      itemsPlan.map(item => {
        worksheet.addRow([
          item.id,
          item.numero_operacao,
          item.cliente_id,
          item.credor,
          item.nome,
          item.cpf_cnpj,
          item.status_operacao,
          item.forma_pagamento,
        ])
      })

      worksheet.eachRow({ includeEmpty: true }, (row, rowNumber) => {
        row.eachCell({ includeEmpty: true }, cell => {
          if (rowNumber === 1) {
            cell.font = {
              name: 'Calibri',
              family: 2,
              bold: true,
              size: 11,
              color: { argb: 'FFFFFF' },
            }
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'fd8426' },
            }
          }
          cell.border = {
            top: { style: 'thin', color: { argb: '000000' } },
            left: { style: 'thin', color: { argb: '000000' } },
            right: { style: 'thin', color: { argb: '000000' } },
            bottom: { style: 'thin', color: { argb: '000000' } },
          }
          cell.alignment = {
            horizontal: 'center',
            vertical: 'center',
          }
        })
      })
      worksheet.columns.forEach(column => {
        let dataMax = 0
        column.eachCell({ includeEmpty: true }, cell => {
          const columnLength = cell.value ? cell.value.length : 15
          if (columnLength > dataMax) { dataMax = columnLength + 5 }
        })
        column.width = dataMax < 15 ? 15 : dataMax
      })

      let blob = null

      await workbook.xlsx.writeBuffer().then(data => {
        // eslint-disable-next-line camelcase
        blob = new File([data], 'Relatório Retirada Negativação', { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      })
      return blob
    },
  },
}
</script>
